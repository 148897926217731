@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,600);
@charset "UTF-8";

body {
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.6em;
  background-color: #fff;
  color: #2c2c2c;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  color: #148f29;
}
.credits a {
  color: #0a4b15;
}

.bg-primary {
  background: #1BBD36 !important;
  color: #fff !important;
}
.btn-primary {
  background: #1BBD36 !important;
  color: #fff !important;
}

.navbar-brand h1 {
  font-size: 28px;
  color: darkgray;
  font-weight: bold !important;
  text-transform: uppercase !important;
}
.navbar-brand h1 span {
  color: #1BBD36;
}
.navbar-brand img {
  height: 32px;
}
.menu .nav-link {
  font-weight: bold !important;
  text-transform: uppercase !important;
  text-decoration: none;
  color: #1BBD36 !important;
}

.menu .active .nav-link {
  color: #0c9923 !important;
}

.navbar-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

header {
  margin-bottom: 4em;
}

.table-pane {
	width: 100%;
	overflow-x: auto;
}

/* Chrome, Safari, Edge, Opera */
.form-commande input::-webkit-outer-spin-button,
.form-commande input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.form-commande input[type=number] {
  -moz-appearance: textfield;
}

.select2-container {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.select2-selection {
	border: none !important;
}
.select2-selection__arrow {
	top: 5px !important;
}


#main-slider .carousel-content {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
}

#main-slider .carousel .item {
  height: calc(100vh - 4em);
  overflow: hidden;
}
#main-slider .carousel-content {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 75vh;
}
@media (max-width: 767px) {
  #main-slider .carousel-content {
    height: calc(100vh - 4em);
  }
}
#main-slider .carousel h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 800;
}
#main-slider .carousel h2 span {
  color: #1BBD36;
  text-shadow: 0 0 2px #FFF;
}
#main-slider .carousel p {
  color: #ddd;
  font-size: 25px;
}


.social-icon {
  list-style: none;
}
.social-icon li {
  margin: 0 10px;
}
.social-icon li:first-child {
  margin-left: 0;
}
.social-icon li:last-child {
  margin-right: 0;
}
.social-icon a {
  font-size: 28px;
}
.social-icon .facebook:hover {
  color: #0c0cc4;
}
.social-icon .twitter:hover {
  color: #0fb6e9;
}
.social-icon .google-plus:hover {
  color: #ff004c;
}
.social-icon .youtube:hover {
  color: #c40c0c;
}



a.scrollup {
  color: #fff;
  background-color: #1BBD36;
  height: 40px;
  width: 40px;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  position: fixed;
  bottom: 30px;
  right: 40px;
  opacity: 0.5;
  border-radius: 50%;
  z-index: 999;
}

a.scrollup:hover {
  color: #fff;
  background-color: #1BBD36;
  opacity: 1;
}


.vspace-30 {
  height: 22em;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
  position: absolute;
}
@media (orientation: portrait) {
  .full-height {
    width: 100%;
    height: auto;
  }
  div.overflow-hidden{
    height: 200px;
  }
}


.selectible:hover {
  background-color: #1bbd3659;
  cursor: pointer;
}
.selectible:hover img {
  opacity: 0.6;
}
.cursor-pointer {
  cursor: pointer;
}
.text-black {
  color: black;
}
.text-wrap {
  white-space: pre-line !important;
}



.table-pane {
  width: 100%;
  overflow-x: auto;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

